import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

import StoreContainer from '../../components/StoreContainer';
import PurchaseBlock from '../../components/PurchaseBlock';
import SubscriptionBlock from '../../components/SubscriptionBlock';
import LicenseBlock from '../../components/LicenseBlock';
import FAQBlock from '../../components/FAQBlock';
import FooterContentForLicenses from '../../components/FooterContentForLicenses';

import { OmniFocusLicenses, OmniFocusFeatures } from '../../constants/products';
import * as vars from '../../styles/variables';

const OFContainer = styled.div`
    display: grid;
    grid-template-areas: 
        'subscription license license'
        'offtw license license'
        'faq faq faq'
    ;
    gap: 30px;
    justify-content: center;

    @media screen and (max-width: 1000px) {
        grid-template-areas:
            'subscription'
            'license'
            'offtw'
            'faq'
        ;
        justify-items: center;
    }
`;

const AppDescription = styled.p`
    max-width: 800px;
    margin: 10px auto;
    color: ${vars.whiteColor};
`;

const OmniFocus: React.FC = () => {
    const [licenseType, setLicenseType] = React.useState('subscription');
    const purchaseApiUrl = useSelector((state: OmniStore.GlobalReduxState) => state.apiURLs.purchaseAPIURL);

    React.useEffect(() => {
        if (window.location.pathname.includes('subscription')) {
            setLicenseType('subscription');
        } else if (window.location.pathname.includes('license')) {
            setLicenseType('ownership');
        }
        console.log(licenseType);
    }, []);

    return (
        <StoreContainer>
            <Helmet>
                <meta
                    name="description"
                    content="One-off payment and subscription options are available for OmniFocus. Save up to 50% with upgrade discounts. 14-day trials available without purchase."
                />
            </Helmet>
            <AppDescription>
                <FormattedMessage id='OmniFocusDescription' />{' '}
            </AppDescription>
            <PurchaseBlock />
            <OFContainer>
                <SubscriptionBlock
                    backgroundColor={vars.whiteColor}
                    blockableVendingIds={['OMNIFOCUS_ALL']}
                    color={vars.purple}
                    dateAddedToCart={0}
                    displayName='OmniFocus Subscription'
                    gridArea={"Subscription"}
                    header={'Subscription'}
                    monthlyPrice={999}
                    monthlyStripeID={
                        purchaseApiUrl.includes('test')
                        ? 'TEST_OMNIFOCUS_DAILY'
                        : 'STRIPE_OMNIFOCUS_ALL_MONTHLY'
                    }
                    payInterval={false}
                    platform="Mac - iPhone - iPad - Apple Watch - Apple Vision Pro - Web"
                    quantity={1}
                    requiredOSString='Requires macOS 13, iOS/iPadOS 16, watchOS 9, visionOS 1.0, or newer'
                    shortName='omnifocus'
                    yearlyPrice={9999}
                    yearlyStripeID={
                        purchaseApiUrl.includes('test')
                        // ? 'TEST_OMNIFOCUS_WEEKLY'
                        ? 'STRIPE_OMNIFOCUS_ALL_ANNUAL'
                        : 'STRIPE_OMNIFOCUS_ALL_ANNUAL'
                    }
                />
                <LicenseBlock 
                    backgroundColor={vars.whiteColor}
                    color={vars.darkPurple}
                    dateAddedToCart={0}
                    displayName='OmniFocus 4 Licenses'
                    features={OmniFocusFeatures}
                    header={['v4 Standard License', 'v4 Pro License']}
                    platform='Mac - iPhone - iPad - Apple Watch - Apple Vision Pro'
                    products={OmniFocusLicenses}
                    productVersion="v4"
                    quantity={1}
                    shortName='omnifocus'
                    // specialOffer={'Buy today, receive a free upgrade to OmniFocus 4 when it ships.'}
                    standardOrPro='PRO'
                /> 
                <SubscriptionBlock
                    backgroundColor={vars.whiteColor}
                    blockableVendingIds={['OMNIFOCUS_ALL']}
                    color={vars.darkPurple}
                    dateAddedToCart={0}
                    displayName='OmniFocus Web Add-On Subscription'
                    gridArea={'offtw'}
                    header={'OmniFocus for the Web'}
                    monthlyPrice={499}
                    monthlyStripeID={'STRIPE_OMNIFOCUS_WEB_MONTHLY'}
                    offtw={true}
                    payInterval={false}
                    platform=""
                    quantity={1}
                    requiredOSString=''
                    shortName='omnifocus'
                    yearlyPrice={4999}
                    yearlyStripeID={'STRIPE_OMNIFOCUS_WEB_ANNUAL'}
                /> 
                <FAQBlock/>
            </OFContainer>
            <FooterContentForLicenses appStoreLink='https://www.omnigroup.com/download/appstore/omnifocus' />
        </StoreContainer>
    );
};

export default OmniFocus;
